import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Grid,
  Center,
  HStack,
  Heading,
  GridItem,
  useToast,
  Textarea,

} from "@chakra-ui/react";
//import { getFormSubmissionInfo } from "react-router-dom/dist/dom";

import axios from "axios";
import { Navigate } from "react-router-dom";

const NewBooking = () => {
  const [Render, setRender] = useState(false)
  const [projectName, setProjectName] = useState("");
  const [blockName, setBlockname] = useState("");
  const [plotName, setPlotName] = useState("");
  const [contractorName, setcontractorName] = useState("");
  const [plottype, setplottype] = useState("");
  const [registerygender, setregisterygender] = useState("");
  const [constructionapplicable, setconstructionapplicable] = useState("");
  const [broker, setBroker] = useState("");
  const plotTypes = ["Normal", "EWS", "1BHK", "2BHK", "3BHK", "4BHK", "5BHK"]; // Replace with actual plot types
  const genders = ["Male", "Female"]; // Replace with actual gender options
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discountApplicable, setDiscountApplicable] = useState('No'); // Initial state
  const [userRight, setUserRight] = useState(localStorage.getItem("userRight"));

  const toast = useToast();
  const [formData, setFormData] = useState({
    projectName: "",
    blockName: "",
    plotNo: "",
    plotType: "",
    customerName: "",
    customerAddress: "",
    customerContact: "",
    registryGender: "",
    areaSqft: "",
    rateAreaSqft: "",
    totalAmount: "",
    discountApplicable: "No",
    discountPercent: "",
    netAmount: "",
    registryAmount: "",
    serviceAmount: "",
    maintenanceAmount: "",
    miscAmount: "",
    grandTotal: "",
    constructionApplicable: "No",
    constructionContractor: "",
    totalAmountPayable: "",
    guidelineAmount: "",
    registryPercent: "",
    bankAmountPayable: "",
    cashAmountPayable: "",
    bookingDate: "",
    constructionAmount: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the corresponding form field
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Calculate the grandTotal based on the updated form data
      const grandTotal =
        parseFloat(updatedData.netAmount || 0) +
        parseFloat(updatedData.registryAmount || 0) +
        parseFloat(updatedData.serviceAmount || 0) +
        parseFloat(updatedData.maintenanceAmount || 0) +
        parseFloat(updatedData.miscAmount || 0);

      // Calculate totalAmountPayable and cashAmountPayable
      const totalAmountPayable = grandTotal;
      const cashAmountPayable = totalAmountPayable - parseFloat(updatedData.bankAmountPayable || 0);

      return {
        ...updatedData,
        grandTotal,
        totalAmountPayable,
        cashAmountPayable,
      };
    });
  };
  const onAddBook = async () => {
    const {
      projectName,
      blockName,
      plotNo,
      customerName,
      plotType,
      customerAddress,
      customerContact,
      registryGender,
      areaSqft,
      rateAreaSqft,
      totalAmount,
      discountApplicable,
      discountPercent,
      netAmount,
      registryAmount,
      serviceAmount,
      maintenanceAmount,
      miscAmount,
      grandTotal,
      constructionApplicable,
      constructionContractor,
      constructionAmount,
      totalAmountPayable,
      guidelineAmount,
      registryPercent,
      bankAmountPayable,
      bookingDate,
      cashAmountPayable,
      remarks,
    } = formData;

    if (!projectName || !blockName || !plotNo || !customerName) {
      alert("Please fill in all required fields");
      return;
    }

    setIsSubmitting(true);

    const url = "https://lkgexcel.com/admin/setQuery.php";
    let query = `
      INSERT INTO booking (id, projectName, blockName, plotNo, plotType, customerName, customerAddress, customerContact, registryGender, areaSqft, rateAreaSqft, totalAmount, discountApplicable, discountPercent, netAmount, registryAmount, serviceAmount, maintenanceAmount, miscAmount, grandTotal, constructionApplicable, constructionContractor, constructionAmount, totalAmountPayable, guidelineAmount, registryPercent, bankAmountPayable, bookingDate, cashAmountPayable, broker, remarks) 
      VALUES (NULL, '${projectName}', '${blockName}', '${plotNo}', '${plotType}', '${customerName}', '${customerAddress}', '${customerContact}', '${registryGender}', '${areaSqft}', '${rateAreaSqft}', '${totalAmount}', '${discountApplicable}', '${discountPercent}', '${netAmount}', '${registryAmount}', '${serviceAmount}', '${maintenanceAmount}', '${miscAmount}', '${grandTotal}', '${constructionApplicable}', '${constructionContractor}', '${constructionAmount}', '${totalAmountPayable}', '${guidelineAmount}', '${registryPercent}', '${bankAmountPayable}', '${bookingDate}', '${cashAmountPayable}', '${remarks}')`;

    let fData = new FormData();
    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
      console.log(response);
      window.location.reload();
      toast({
        title: "Booking added successfully!",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });

      // Clear form data
      setFormData({
        projectName: "",
        blockName: "",
        plotNo: "",
        plotType: "",
        customerName: "",
        customerAddress: "",
        customerContact: "",
        registryGender: "",
        areaSqft: "",
        rateAreaSqft: "",
        totalAmount: "",
        discountApplicable: "No",
        discountPercent: "",
        netAmount: "",
        registryAmount: "",
        serviceAmount: "",
        maintenanceAmount: "",
        miscAmount: "",
        grandTotal: "",
        constructionApplicable: "No",
        constructionContractor: "",
        constructionAmount: "",
        totalAmountPayable: "",
        guidelineAmount: "",
        registryPercent: "",
        bankAmountPayable: "",
        cashAmountPayable: "",
        bookingDate: "",
        remarks: "",
      });

      setIsSubmitting(false);
    } catch (error) {
      console.log(error.toJSON());
      setIsSubmitting(false);
    }
  };

  const updatePlotStatus = async () => {
    const url = "https://lkgexcel.com/admin/setQuery.php";
    let query =
      "UPDATE plot SET plotStatus = 'Booked' WHERE plotNo = '" +
      plotName +
      "' AND projectName ='" +
      projectName +
      "' AND blockName  ='" +
      blockName + "' ;";

    let fData = new FormData();
    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
    } catch (error) {
      console.log(error.toJSON());
    }
  };

  const [projectsData, setprojectsData] = useState([]);
  const [blockData, setblockData] = useState([]);
  const [plotData, setplotData] = useState([]);
  const [contractorData, setcontractorData] = useState([]);
  const [master, setMaster] = useState([]);
  const [brokerData, setBrokerData] = useState([]);
  const loadBlocks = async (pname) => {
    let query = "SELECT * FROM block where projectName = '" + pname + "' ;";
    

    const url = "https://lkgexcel.com/admin/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setblockData(response.data.phpresult);
          console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadContractor = async () => {
    let query = "SELECT * FROM contractor;";
    const url = "https://lkgexcel.com/admin/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setcontractorData(response.data.phpresult);
          console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };
  const loadBroker = async () => {
    let query = "SELECT * FROM broker;";
    // alert(query);

    const url = "https://lkgexcel.com/admin/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setBrokerData(response.data.phpresult);
          console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadPlots = async (bname) => {
    let query =
      "SELECT * FROM plot where blockName = '" +
      bname +
      "' AND projectName ='" +
      projectName +
      "' AND plotStatus ='Available' ;";

    const url = "https://lkgexcel.com/admin/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
      console.log(response);

      if (response && response.data) {
        if (response.data.phpresult) {
          setplotData(response.data.phpresult);
          console.log("Ploat DAta : ", response.data);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadProjects = async () => {
    let query = "SELECT * FROM project;";
    // alert(query);

    const url = "https://lkgexcel.com/admin/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setprojectsData(response.data.phpresult);
          console.log("project data", response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const [myValue, setMyValue] = useState("")    

 const onSelectPlot = async (pno) => {
  let query =
    "SELECT * FROM plot WHERE blockName = '" +
    blockName +
    "' AND projectName ='" +
    projectName +
    "' AND plotStatus ='Available' AND plotNo='" +
    pno +
    "';";

  const url = "https://lkgexcel.com/admin/getQuery.php";
  let fData = new FormData();
  fData.append("query", query);

  try {
    const response = await axios.post(url, fData);

    if (response && response.data) {
      if (response.data.phpresult) {
        console.log("onselect plot No : ", response.data.phpresult);

        let query1 = "SELECT * FROM master WHERE projectName ='" + projectName + "';";
        const url1 = "https://lkgexcel.com/admin/getQuery.php";
        let fData1 = new FormData();
        fData1.append("query", query1);

        const response1 = await axios.post(url1, fData1);

        if (response1 && response1.data) {
          if (response1.data.phpresult) {
            setMaster(response1.data?.phpresult);
console.log("response1", response1.data.phpresult);
            // Set formData state based on fetched values
            setFormData((prevData) => ({
              ...prevData,
              plotType: response.data.phpresult[0]["plotType"],
              areaSqft: response.data.phpresult[0]["areaSqft"],
              rateAreaSqft: response.data.phpresult[0]["ratePerSqft"],
              totalAmount: response.data.phpresult[0]["areaSqft"] * response.data.phpresult[0]["ratePerSqft"],
              netAmount: response.data.phpresult[0]["areaSqft"] * response.data.phpresult[0]["ratePerSqft"],
              guidelineAmount: response.data.phpresult[0]["areaSqmt"] * response1.data.phpresult[0]["guideline"],
              registryPercent: response1.data.phpresult[0]["registryMalePercent"], // Based on gender
              registryAmount: (response.data.phpresult[0]["areaSqmt"] * response1.data.phpresult[0]["guideline"] * response1.data.phpresult[0]["registryMalePercent"]) / 100,
              serviceAmount: response1.data.phpresult[0]["serviceType"] === "Lumpsum" ? response1.data.phpresult[0]["serviceValue"] : response1.data.phpresult[0]["serviceValue"] * response.data.phpresult[0]["areaSqft"],
              maintenanceAmount: response1.data.phpresult[0]["maintenanceType"] === "Lumpsum" ? response1.data.phpresult[0]["maintenanceValue"] : response1.data.phpresult[0]["maintenanceValue"] * response.data.phpresult[0]["areaSqft"],
              miscAmount: response1.data.phpresult[0]["miscType"] === "Lumpsum" ? response1.data.phpresult[0]["miscValue"] : response1.data.phpresult[0]["miscValue"] * response.data.phpresult[0]["areaSqft"],
              grandTotal: parseFloat(response.data.phpresult[0]["areaSqft"] * response.data.phpresult[0]["ratePerSqft"]) +
                          parseFloat(response1.data.phpresult[0]["serviceValue"]) +
                          parseFloat(response1.data.phpresult[0]["maintenanceValue"]),
              totalAmountPayable: response1.data.phpresult[0]["serviceType"] === "Yes" ? (parseFloat(response.data.phpresult[0]["areaSqft"] * response.data.phpresult[0]["ratePerSqft"]) + parseFloat(response1.data.phpresult[0]["serviceValue"])) : parseFloat(response.data.phpresult[0]["areaSqft"] * response.data.phpresult[0]["ratePerSqft"]),
              bankAmountPayable: response.data.phpresult[0]["guidelineAmount"],
             
              // bankAmountPayable: response.data.phpresult[0]["areaSqmt"] * response1.data.phpresult[0]["guideline"],
              // registryAmount: response1.data.phpresult[0]["registryAmount"]
            }));
          }
        }
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

const [areaSqft, setAreaSqft] = useState("")
const [rateAreaSqft, setRateAreaSqft] = useState(0);
const [totalAmount, setTotalAmount] = useState(0);
const [netAmount, setNetAmount] = useState(0);
// const [discountApplicable, setDiscountApplicable] = useState("No");
const [discountPercent, setDiscountPercent] = useState(0);
const [guidelineAmount, setGuidelineAmount] = useState(0);
const [registryGender, setRegistryGender] = useState("Male");
const [registryPercent, setRegistryPercent] = useState(0);
const [registryAmount, setRegistryAmount] = useState(0);
const [serviceAmount, setServiceAmount] = useState(0);
const [maintenanceAmount, setMaintenanceAmount] = useState(0);
const [miscAmount, setMiscAmount] = useState(0);
const [grandTotal, setGrandTotal] = useState(0);
const [constructionAmount, setConstructionAmount] = useState(0);
const [totalAmountPayable, setTotalAmountPayable] = useState(0);
const [bankAmountPayable, setBankAmountPayable] = useState(0);
const [cashAmountPayable, setCashAmountPayable] = useState(0);

// Assuming plotData and master are available
// const plotData = [{ areaSqft: 100, areaSqmt: 92 }];
// const master = [{ guideline: 10, registryMalePercent: 5, registryFemalePercent: 6, serviceType: "Lumpsum", serviceValue: 100, maintenanceType: "Lumpsum", maintenanceValue: 50, miscType: "Lumpsum", miscValue: 30 }];

const updateOnChange = () => {
  // Calculate total amount
  const newTotalAmount = areaSqft * rateAreaSqft;
  setTotalAmount(newTotalAmount);

  // Calculate net amount based on discount
  if (formData.discountApplicable === "Yes") {
    const discountAmount = (newTotalAmount * discountPercent) / 100;
    const newNetAmount = newTotalAmount - discountAmount;
    setNetAmount(newNetAmount);
  } else {
    setNetAmount(newTotalAmount);
  }

  // Set guideline amount
  const newGuidelineAmount = plotData[0].areaSqmt * master[0].guideline;
  setGuidelineAmount(newGuidelineAmount);

  // Set registry percent based on gender
  if (registryGender === "Male") {
    setRegistryPercent(master[0].registryMalePercent);
  } else if (registryGender === "Female") {
    setRegistryPercent(master[0].registryFemalePercent);
  }

  // Calculate registry amount
  const newRegistryAmount = (newGuidelineAmount * registryPercent) / 100;
  setRegistryAmount(newRegistryAmount);

  // Calculate service, maintenance, and misc amounts
  if (master[0].serviceType === "Lumpsum") {
    setServiceAmount(master[0].serviceValue);
  } else if (master[0].serviceType === "PerSqmt") {
    setServiceAmount(master[0].serviceValue * plotData[0].areaSqft);
  }

  if (master[0].maintenanceType === "Lumpsum") {
    setMaintenanceAmount(master[0].maintenanceValue);
  } else if (master[0].maintenanceType === "PerSqmt") {
    setMaintenanceAmount(master[0].maintenanceValue * plotData[0].areaSqft);
  }

  if (master[0].miscType === "Lumpsum") {
    setMiscAmount(master[0].miscValue);
  } else if (master[0].miscType === "PerSqmt") {
    setMiscAmount(master[0].miscValue * plotData[0].areaSqft);
  }

  // Calculate grand total
  const newGrandTotal =
    netAmount + registryAmount + serviceAmount + maintenanceAmount + miscAmount;
  setGrandTotal(newGrandTotal);

  // Calculate total amount payable including construction
  if (constructionAmount > 0) {
    setTotalAmountPayable(newGrandTotal + constructionAmount);
  } else {
    setTotalAmountPayable(newGrandTotal);
  }

  // Calculate bank and cash payable amounts
  const newBankAmountPayable = (newGuidelineAmount * 100) / 100; // Example, assuming 100% registry
  setBankAmountPayable(newBankAmountPayable);



  
 

};

useEffect(()=> {

if(formData.totalAmountPayable && formData.guidelineAmount){
  const newCashAmountPayable =  formData.totalAmountPayable - formData.guidelineAmount;
  setCashAmountPayable(newCashAmountPayable);
}


},[formData])



 
  useEffect(() => {
    loadProjects();
    loadContractor();
    loadBroker();
  }, []);
  const [phone, setPhone] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;

    const formattedValue = value.replace(/\D/g, '');

    if (formattedValue.length === 10) {
      setPhone(formattedValue);

    } else {
      setPhone(formattedValue);
    }
  };
console.log("hey",formData)
console.log("cashAmount", cashAmountPayable)
console.log("totalamount",formData.totalAmountPayable);
console.log("bankamount",formData.guidelineAmount);
console.log("grand",formData.grandTotal)
  return (
    <Box p={4} width="100%" position={"relative"} bottom={"0rem"} >
      <Center pb={2}>
        <Heading fontSize={"22px"} position={"relative"} bottom={"1rem"}>
          New Booking
        </Heading>
      </Center>
      <Box position={"relative"} bottom={"1rem"}>
        <form onSubmit={onAddBook}>
          <Grid templateColumns="repeat(4, 1fr)" gap={1}>
            <FormControl colSpan={1} isRequired>
              <FormLabel>Project Name</FormLabel>
              <Select
                id="projectName"
                name="state"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  loadBlocks(e.target.value);
                }}
                placeholder="Select Project"
              >
                {projectsData.map((project) => {
                  return (
                    <option
                      key={project.projectName}
                      value={project.projectName}
                    >
                      {project.projectName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Block Name</FormLabel>
              <Select
                id="blockName"
                name="state"
                value={blockName}
                onChange={(e) => {
                  setBlockname(e.target.value);
                  loadPlots(e.target.value);
                }}
                placeholder="Select Block"
              >
                {blockData.map((block) => {
                  return (
                    <option key={block.blockName} value={block.blockName}>
                      {block.blockName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Plot No</FormLabel>
              <Select
                id="plotNo"
                name="state"
                value={plotName}
                onChange={(e) => {
                  setPlotName(e.target.value);
                  onSelectPlot(e.target.value);
                }}
                placeholder="Select Plot No"
              >
                {plotData.map((plot) => {
                  return (
                    <option key={plot.plotNo} value={plot.plotNo}>
                      {plot.plotNo}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Plot Type</FormLabel>
              <Input
                // onChange={updateOnChange}
                id="plotType"
                value={formData.plotType}
                type="text"
                required
              />
            </FormControl>


            <FormControl>
              <FormLabel>Customer Name</FormLabel>
              <Input
                id="customerName"
                type="text"
                name="customerName"
                onChange={handleChange}
                required
                
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Address</FormLabel>
              <Input
                id="customerAddress"
                type="text"
                name="customerAddress"
                //onChange={handleChange}
                required
                          

              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Contact</FormLabel>
    

              <Input
                id="customerContact"
                type="text"
                name="customerContact"
                onChange={handleInputChange}
                required
              />

            </FormControl>

            <FormControl>
              <FormLabel>Registry Gender</FormLabel>
              <Select
                id="registryGender"
                name="registryGender"
                onChange={(e) => {
                  setregisterygender(e.target.value);
                  updateOnChange();
                }}
                //onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Gender
                </option>
                {genders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Area Sqft</FormLabel>
              <Input
                onChange={updateOnChange}
                id="areaSqft"
                type="text"
                name="areaSqft"
                value={formData.areaSqft}
                //onChange={handleChange}
                required
                disabled={userRight === "Manager" || userRight === "SalesPerson"}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Rate Per Sqft</FormLabel>
              <Input
                onChange={updateOnChange}
                id="rateAreaSqft"
                type="text"
                name="rateAreaSqft"
                value={formData.rateAreaSqft}
                //onChange={handleChange}
                required
                disabled={userRight === "Manager" || userRight === "SalesPerson"}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Total Amount</FormLabel>
              <Input
                onChange={updateOnChange}
                id="totalAmount"
                type="text"
                name="totalAmount"
                value={formData.totalAmount}
                //onChange={handleChange}
                required
                bg={"yellow"}
                color={"black"}
                disabled={userRight === "Manager" || userRight === "SalesPerson"}
              />
            </FormControl>
            <Box gridColumn="span 1" />
            {/* <FormControl>
              <FormLabel>Discount Applicable</FormLabel>
              <Select
                id="discountApplicable"
                name="discountApplicable"
                onChange={(e) => {
                  setdiscountApplicable(e.target.value);

                }}
                //onChange={handleChange}
                required
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
            </FormControl> */}
            <FormControl>
              <FormLabel>Discount Applicable</FormLabel>
              <Select
                id="discountApplicable"
                name="discountApplicable"
                value={formData.discountApplicable} // Controlled component
                onChange={handleChange} // Event handler for changes
                required
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Discount Percent</FormLabel>
              <Input
                onChange={updateOnChange}
                id="discountPercent"
                type="text"
                name="discountPercent"
                disabled={formData.discountApplicable === "No" || userRight === "Manager" || userRight === "SalesPerson"}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Net Amount</FormLabel>
              <Input
                onChange={updateOnChange}
                id="netAmount"
                type="text"
                name="netAmount"
                value={formData.netAmount}
                //onChange={handleChange}
                required
                bg={"yellow"}
                
              />
            </FormControl>
            <Box gridColumn="span 1" />
            <FormControl>
              <FormLabel>Registry Amount</FormLabel>
              <Input
                // onChange={updateOnChange}
                id="registryAmount"
                type="number"
value={formData.registryAmount}
                name="registryAmount"
                onChange={handleChange}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Service Amount</FormLabel>
              <Input
                // onChange={updateOnChange}
                id="serviceAmount"
                type="number"
                name="serviceAmount"
                value={formData.serviceAmount}
                onChange={handleChange}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Maintenance Amount</FormLabel>
              <Input
                // onChange={updateOnChange}
                id="maintenanceAmount"
                type="number"
                name="maintenanceAmount"
                value={formData.maintenanceAmount}
                required
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Misc Amount</FormLabel>
              <Input
                // onChange={updateOnChange}
                id="miscAmount"
                type="number"
                name="miscAmount"
                value={formData.miscAmount}

                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Grand Total</FormLabel>
              <Input
                onChange={updateOnChange}
                id="grandTotal"
                type="text"
                name="grandTotal"
                value={formData.grandTotal}
                //onChange={handleChange}
                bg={"yellow"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Construction Applicable</FormLabel>
              <Select
                id="constructionApplicable"
                value={formData.constructionapplicable}
                onChange={(e) => {
                  setconstructionapplicable(e.target.value);
                  updateOnChange();
                }}
                name="constructionApplicable"
                //onChange={handleChange}
                required
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
                
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Construction Contractor</FormLabel>

              <Select
                id="constructionContractor"
                type="text"
                name="constructionContractor"
                value={contractorName}
                onChange={(e) => {
                  setcontractorName();
                }}
                placeholder="Select Contactor"
              >
                {contractorData.map((block) => {
                  return (
                    <option
                      key={block.contractorName}
                      value={block.contractorName}
                    >
                      {block.contractorName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Construction Amount</FormLabel>
              <Input
                onChange={updateOnChange}
                id="constructionAmount"
                type="text"
                name="constructionAmount"

              //onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Total Amount Payable</FormLabel>
              <Input
                onChange={updateOnChange}
                id="totalAmountPayable"
                type="text"
                value={formData.totalAmountPayable}
                name="totalAmountPayable"
                //onChange={handleChange}
                bg={"yellow"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Guideline Amount</FormLabel>
              <Input
                onChange={updateOnChange}
                value={formData.guidelineAmount}
                id="guidelineAmount"
                type="text"
                name="guidelineAmount"

              //onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Registry Percent</FormLabel>
              <Input
                type="text"
                onChange={updateOnChange}
                id="registry"
                value={formData.registryPercent}
              // onChange={handleChange}
              />
              <Input
                onChange={updateOnChange}
                id="registryPercent"
                value={formData.registryPercent}
                
                type="text"
                name="registryPercent"
                style={{ display: "none" }}
              // onChange={handleChange}
              />
            </FormControl>
            <Box gridColumn="span 1" />
            <Box
              display="grid"
              gridAutoFlow="column"
              position={"absolute"}
              top={"100%"}
              gap={"2rem"}
            >
              <FormControl>
                <FormLabel>Bank Amount Payable</FormLabel>
                <Input
                  // onChange={updateOnChange}
                  id="bankAmountPayable"
                  type="text"
                  name="bankAmountPayable"
                  onChange={handleChange}
                  value={formData.guidelineAmount}
                  bg={"yellow"}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Cash Amount Payable</FormLabel>
                <Input
                  onChange={updateOnChange}
                  id="cashAmountPayable"
                  type="text"
                  value={cashAmountPayable}
                  name="cashAmountPayable"
                  //onChange={handleChange}
                  bg={"yellow"}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Booking Date</FormLabel>
                <Input
                  onChange={updateOnChange}
                  id="bookingDate"
                  type="date"
                  name="bookingDate"
                  //onChange={handleChange}
                  required
                  defaultValue={new Date().toISOString().slice(0, 10)}

                />
              </FormControl>
              <FormControl>
                <FormLabel>Broker</FormLabel>

                <Select
                  id="broker"
                  type="text"
                  name="broker"
                  value={broker}
                  onChange={(e) => {
                    setBroker();
                  }}
                  placeholder="Select "
                >
                  {brokerData.map((block) => {
                    return (
                      <option key={block.brokerName} value={block.brokerName}>
                        {block.brokerName}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Remarks</FormLabel>
                <Textarea
                  onChange={updateOnChange}
                  id="remarks"
                  type="text"
                  name="remarks"
                  //onChange={handleChange}
                  required
                  width={"320px"}
                  rows={2}
                />

              </FormControl>


              <Button
                colorScheme="blue"
                type="button"
                mt={8}
                onClick={onAddBook}
                isDisabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>



            </Box>
          </Grid>
        </form>
      </Box>
      <br /><br />
    </Box>
  );
};

export default NewBooking;



